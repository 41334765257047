import _ from 'lodash';
export function toSnakeCase(value) {
    return value.replace(/([A-Z])/g, '_$1');
}
export function toNumberIfPossible(value) {
    if (typeof value === 'number') {
        return value;
    }
    var int = parseInt(value);
    return int.toString() === value ? int : value;
}
export function formatByMask(value, mask, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.placeholder, placeholder = _c === void 0 ? '.' : _c, _d = _b.strict, strict = _d === void 0 ? true : _d;
    if (!mask) {
        return value;
    }
    var tmp = mask.split('').reduce(function (acc, character) {
        if (!acc.remaining.length) {
            return acc;
        }
        if (character === placeholder) {
            return {
                formatted: acc.formatted + acc.remaining[0],
                remaining: acc.remaining.slice(1),
            };
        }
        return {
            formatted: acc.formatted + character,
            remaining: acc.remaining,
        };
    }, { formatted: '', remaining: value.split('') });
    var result = tmp.formatted;
    if (!strict) {
        result = result + tmp.remaining.join('');
    }
    return result;
}
export function firstLetters(names) {
    if (names === void 0) { names = []; }
    names = names.map(function (x) { return (typeof x === 'undefined' ? '' : x); });
    return names
        .map(function (x) { return x.trim(); })
        .filter(function (x) { return x; })
        .map(function (x) { return x[0]; })
        .join('');
}
// todo: add zero as free
export function formatAmount(amount, currency, precision) {
    if (precision === void 0) { precision = 2; }
    var curr = currency.toUpperCase();
    var result;
    if (curr === 'USD') {
        result = '$' + _.floor(amount, precision);
    }
    else {
        result = _.floor(amount, precision) + ' ' + curr;
    }
    return result;
}
export function humanize(str, startCase) {
    if (startCase === void 0) { startCase = false; }
    var strCleaned = _.replace(str, /_/g, ' ');
    return startCase ? _.startCase(strCleaned) : _.capitalize(strCleaned);
}
export function stripHtml(str) {
    return _.replace(str, /(<([^>]+)>)/gi, '');
}
export function valueOrDash(value) {
    return (value && _.trim(value)) || '—';
}
function characterByIndex(index, charStart, charEnd) {
    if (charStart === void 0) { charStart = 'A'; }
    if (charEnd === void 0) { charEnd = 'Z'; }
    var alphabet = [];
    for (var i = charStart.charCodeAt(0); i <= charEnd.charCodeAt(0); i++) {
        alphabet.push(String.fromCharCode(i));
    }
    if (index <= alphabet.length - 1) {
        return alphabet[index];
    }
    else if (index <= Math.pow(alphabet.length, 2) + alphabet.length) {
        var firstChar = '';
        var secondChar = '';
        var ind = 0;
        for (var i = index; i >= 0; i -= alphabet.length) {
            ind++;
            firstChar = alphabet[ind - 2];
            secondChar = alphabet[i];
        }
        return [firstChar, secondChar].join('');
    }
    else {
        return index;
    }
}
