var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import _ from 'lodash';
import moment from 'moment-timezone';
import { setRG } from 'ziphy-web-shared/basic/utils/analytics';
//need to import these to be able to switch between moment locales
// import localeEs from 'moment/locale/es'
// import localePl from 'moment/locale/pl'
// import localeRu from 'moment/locale/ru'
// import localeUk from 'moment/locale/uk'
export var defaultLang;
var languageDetector = new LanguageDetector();
export function initI18N(props) {
    defaultLang = props.default;
    i18n
        .use(intervalPlural)
        .use(initReactI18next)
        .use(Backend)
        .use(languageDetector)
        .init({
        detection: {
            order: ['localStorage', 'navigator'],
        },
        supportedLngs: props.languages,
        nsSeparator: '::',
        fallbackLng: defaultLang,
        // debug: true,
        saveMissing: true,
        returnNull: false,
        missingKeyHandler: function (lngs, ns, key, fallbackValue, updateMissing, options) {
            if (_.trim(key) === '') {
                return false;
            }
            var customData = { lngs: lngs, ns: ns, key: key, fallbackValue: fallbackValue, updateMissing: updateMissing, options: options };
            setRG('send', {
                error: new Error('i18next::translator: missingKey'),
                tags: ['i18n'],
                customData: customData,
            });
            if (process.env.REACT_APP_ENV === 'dev') {
                console.warn('i18next::translator: missingKey', customData);
            }
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            queryStringParams: { v: process.env.REACT_APP_COMMIT_HASH },
            loadPath: "".concat(process.env.PUBLIC_URL, "/translations/{{lng}}.json"),
        },
        load: 'currentOnly',
    })
        .then(function () {
        moment.locale(i18n.language);
        props.onIsLoading(false);
    });
}
export function changeCurrentLanguage(locale) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, i18n.changeLanguage(locale)];
                case 1:
                    _a.sent();
                    moment.locale(i18n.language);
                    return [2 /*return*/];
            }
        });
    });
}
