import { initMixpanel } from './mixpanel/mixpanel';
import { initRaygun } from './raygun/raygun';
export { setRG } from './raygun/raygun';
export { analytics } from './zipAnalytics';
export var initAnalytics = function (props) {
    if (window.location.hostname === 'localhost') {
        return;
    }
    if (props.raygun) {
        initRaygun({
            apiKey: props.raygun.apiKey,
            appEnv: props.raygun.appEnv,
            appType: props.raygun.appType,
            appBrand: props.raygun.appBrand,
            appVersion: props.raygun.appVersion,
            appRevision: props.raygun.appRevision,
        });
    }
    if (props.mixpanel) {
        initMixpanel({ apiKey: props.mixpanel.apiKey });
    }
};
