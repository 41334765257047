var _a, _b;
import mainConfig from '@config/main';
export var InitializerConfig = {
    appEnv: mainConfig.appEnv,
    appType: mainConfig.appType,
    appBrand: mainConfig.appBrand,
    appVersion: process.env.REACT_APP_VERSION,
    appRevision: process.env.REACT_APP_COMMIT_HASH,
    i18n: {
        languages: mainConfig.localization.availableLanguages,
        default: mainConfig.localization.defaultLanguage,
    },
    apiService: {
        urls: {
            baseUrl: mainConfig.api.baseUrl,
            codingServiceUrl: mainConfig.api.codingService,
        },
    },
    webView: {
        isEnabled: mainConfig.custom.webViewEnabled,
        prefix: mainConfig.custom.webViewPostMessagePrefix,
    },
    analytics: {
        raygun: { apiKey: (_a = mainConfig.analytic) === null || _a === void 0 ? void 0 : _a.raygunApiKey },
        mixpanel: { apiKey: (_b = mainConfig.analytic) === null || _b === void 0 ? void 0 : _b.mixpanelApiKey },
    },
};
